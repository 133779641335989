@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

.faqAnswer span {
  color: inherit !important;
  font-family: inherit !important;
}

*:focus {
  box-shadow: none !important;
}

.journeyDescription {
  font-weight: 500;
  color: #3f6870;
}

.journeyDescription ul {
  padding-left: 14px;
}

.aboutBanner {
  background-image: url("/assets/images/aboutus/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 1920px;
  height: 492px;
  background-position: center;
}

.partnerBanner {
  background-image: url("/assets/images/partners_page/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 1920px;
  height: 436px;
  background-position: center;
}

.blogBanner {
  background-image: url("/assets/images/blog/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 1920px;
  height: 434px;
  background-position: center;
}

.title {
  height: 0;
  opacity: 0;
}

@media (max-width: 600px) {
  .journeyDescription {
    font-size: 12px;
    line-height: 18px;
  }
  .aboutBanner {
    background-image: url("/assets/images/aboutus/bg-mobile.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 244px;
    background-position: center;
  }
  .partnerBanner {
    background-image: url("/assets/images/partners_page/bg-mobile.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 166px;
    background-position: center;
  }
  .blogBanner {
    background-image: url("/assets/images/blog/bg-mobile.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 248px;
    background-position: center;
  }
}

@media only screen and (max-width: 992px) {
  .chakra-modal__content-container {
    z-index: 10000 !important;
  }
}

div[id*="mosaic-provider-react-aria"] {
  width: 100%;
}

@media (max-width: 992px) {
  div[id*="mosaic-provider-react-aria"] {
    padding: 1rem;
  }
}

div[id*="mosaic-provider-react-aria"]
  .sl-flex.sl-overflow-y-auto.sl-flex-col.sl-sticky.sl-border-r {
  height: 100vh;
}

.emailModalContent + .chakra-modal__content-container {
  z-index: 1501 !important;
}
